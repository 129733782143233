define("ember-svg-jar/inlined/micro-arrow-left-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.768 5.182l4.95 4.95 1.414-1.414-3.536-3.536 3.536-3.535L5.718.232z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "8",
      "height": "11",
      "viewBox": "0 0 8 11"
    }
  };
});