define("ember-svg-jar/inlined/filter-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.124 0H.876C.099 0-.294.943.257 1.494L5.25 6.487v5.107c0 .252.109.492.299.658l1.75 1.531c.559.489 1.451.1 1.451-.658V6.487l4.993-4.993c.55-.55.16-1.494-.619-1.494zM7.875 6.125v7l-1.75-1.531V6.125L.875.875h12.25l-5.25 5.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
});