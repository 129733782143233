define("ember-svg-jar/inlined/mini-shield-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#FF9051\" fill-rule=\"evenodd\" d=\"M9.621 6.036A9.036 9.036 0 0010 3.394v-.998H9c-1.29 0-2.816-1.23-3.295-1.702L5.005 0 4.3.69c-.605.59-2.073 1.706-3.3 1.706H0v.998c0 1 .146 1.873.38 2.642a884.188 884.188 0 00.932 1.996c1.33 2.02 3.207 2.781 3.329 2.828L5 11l.361-.14c.122-.047 1.998-.808 3.326-2.828l.933-1.996zM5.002 8.822c-.79-.44-2.631-1.754-2.953-4.559 1.202-.287 2.27-.998 2.95-1.537.67.533 1.741 1.25 2.953 1.538-.32 2.81-2.156 4.117-2.95 4.558z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "11",
      "viewBox": "0 0 10 11"
    }
  };
});