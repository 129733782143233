define("ember-svg-jar/inlined/user-lightbulb-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M5.402 1.8V0h1.8v1.8h-1.8zm5.403 5.403v-1.8h1.8v1.8h-1.8zM0 7.203v-1.8h1.8v1.8H0zm11.66-4.985l-1.275 1.273-1.272-1.273L10.386.946l1.273 1.272zm-8.168 0L2.219 3.491.945 2.218 2.218.946l1.274 1.272zM1.892 16.2a4.508 4.508 0 014.41-3.6 4.51 4.51 0 014.41 3.6h-8.82zm4.41-5.4a6.309 6.309 0 00-6.3 6.3v.9h12.6v-.9c0-3.474-2.826-6.3-6.3-6.3zm0-5.4a.901.901 0 10.9.9c0-.496-.404-.9-.9-.9zm0 3.6a2.704 2.704 0 01-2.7-2.7c0-1.488 1.212-2.7 2.7-2.7 1.49 0 2.7 1.212 2.7 2.7 0 1.49-1.21 2.7-2.7 2.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "18",
      "viewBox": "0 0 13 18"
    }
  };
});