define("ember-svg-jar/inlined/notepad-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M12.6 16.174H1.8v-10.8h6.982L7.2 6.956v3.818h3.818L12.6 9.192v6.982zM9 7.701l4.188-4.187 1.272 1.272-4.187 4.188H9V7.701zm6.937-5.663a.897.897 0 010 1.272l-.203.204L14.46 2.24l.204-.203a.922.922 0 011.273 0zM18 2.674c0-.722-.281-1.4-.79-1.909-1.021-1.02-2.8-1.02-3.82 0l-2.838 2.84v-.03H0v14.4h14.4V7.391l2.809-2.809a2.68 2.68 0 00.79-1.909z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
});