define("ember-svg-jar/inlined/message-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M0 12.6h18V0H0v12.6zM3.873 1.8h10.255L9 5.217 3.873 1.799zm12.327.78v8.22H1.8V2.58L9 7.38l7.2-4.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "13",
      "viewBox": "0 0 18 13"
    }
  };
});