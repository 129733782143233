define("ember-svg-jar/inlined/layers-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 7a.676.676 0 00-.404-.623L10.87 5.194l2.726-1.183A.676.676 0 0014 3.387a.676.676 0 00-.404-.623L7.436.09a1.09 1.09 0 00-.872 0L.405 2.764A.676.676 0 000 3.387c0 .273.159.518.404.623L3.13 5.194.405 6.377A.676.676 0 000 7c0 .273.159.518.404.624L3.13 8.806.405 9.99a.677.677 0 00-.405.624c0 .273.159.518.404.624l6.161 2.673a1.096 1.096 0 00.87 0l6.16-2.673a.676.676 0 00.405-.624.676.676 0 00-.404-.623l-2.727-1.183 2.726-1.183A.676.676 0 0014 7zM1.237 3.387L6.925.92a.19.19 0 01.15 0l5.688 2.468-5.688 2.469a.186.186 0 01-.15 0L1.237 3.387zm11.526 7.226l-5.688 2.468a.186.186 0 01-.15 0l-5.688-2.468L4.265 9.3l2.3.998a1.096 1.096 0 00.87 0l2.3-.998 3.028 1.314zM7.075 9.468a.186.186 0 01-.15 0L1.237 7l3.029-1.314 2.3.998a1.096 1.096 0 00.869 0l2.3-.998 3.028 1.315-5.688 2.467z\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});