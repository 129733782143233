define("ember-svg-jar/inlined/nano-arrow-up-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.9.364l4 3.62-.419.38L4.9 1.123 1.319 4.364.9 3.984z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "9",
      "height": "5",
      "viewBox": "0 0 9 5"
    }
  };
});