define("ember-svg-jar/inlined/task-list-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 3.003v-1a.376.376 0 00-.375-.375h-9.25A.38.38 0 006 2.01v.993c0 .206.17.375.375.375h9.25A.376.376 0 0016 3.003zm0 4.999v-1a.376.376 0 00-.375-.375h-9.25A.376.376 0 006 7.002v1c0 .206.17.375.375.375h9.25A.376.376 0 0016 8.002zM16 13v-1a.376.376 0 00-.375-.374h-9.25A.376.376 0 006 12v1c0 .205.17.374.375.374h9.25A.376.376 0 0016 13zM5.066 1.03L2.191 3.897a.375.375 0 01-.53 0l-.388-.388L.11 2.338a.375.375 0 010-.531l.388-.388a.375.375 0 01.53 0l.9.912L4.152.111a.375.375 0 01.53 0l.388.387a.378.378 0 01-.003.53zm0 4.995L2.191 8.898a.375.375 0 01-.53 0l-.388-.387L.11 7.333a.375.375 0 010-.531l.388-.387a.375.375 0 01.53 0l.9.912 2.223-2.221a.375.375 0 01.53 0l.388.387a.378.378 0 01-.003.531zm-3.065 4.977a1.49 1.49 0 00-1.488 1.5c0 .827.66 1.499 1.488 1.499a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16"
    }
  };
});