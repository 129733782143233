define("ember-svg-jar/inlined/file-download-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.004 3.445L10.055.495A1.687 1.687 0 008.864 0H1.687A1.693 1.693 0 000 1.69v14.623C0 17.244.756 18 1.687 18h10.126c.931 0 1.687-.756 1.687-1.687V4.64c0-.447-.18-.879-.496-1.195zm-4.003-2.3a.553.553 0 01.26.148l2.95 2.95a.553.553 0 01.147.26H9zm3.374 15.168c0 .31-.253.562-.562.562H1.687a.564.564 0 01-.562-.562V1.69c0-.31.253-.566.562-.566h6.19v3.659a.84.84 0 00.843.841h3.655zm-5.063-8.72a.281.281 0 00-.28-.28h-.563a.281.281 0 00-.282.28v3.095h-1.85a.947.947 0 00-.88.588.998.998 0 00.192 1.083l2.394 2.524a.97.97 0 001.413 0L9.85 12.36a1 1 0 00.191-1.084.947.947 0 00-.879-.588h-1.85zm1.507 4.22l-2.069 2.18-2.069-2.18z\"/>",
    "attrs": {
      "height": "18",
      "viewBox": "0 0 14 18",
      "width": "14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});