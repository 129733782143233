define("ember-svg-jar/inlined/micro-outline-close-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.414 5l2.829 2.828-1.415 1.415L5 6.414 2.172 9.243.757 7.828 3.586 5 .757 2.172 2.172.757 5 3.586 7.828.757l1.415 1.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10"
    }
  };
});