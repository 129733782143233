define("ember-svg-jar/inlined/users-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M11.151 12.8a7.12 7.12 0 00-.27-1.307c.343-.184.72-.293 1.12-.293 1.042 0 1.932.669 2.263 1.6H11.15zm-9.495 0c.318-2.267 1.966-4 3.945-4 1.977 0 3.625 1.733 3.944 4h-7.89zM12 9.6c2.204 0 4 1.794 4 4v.8H0v-.8c0-3.529 2.512-6.4 5.6-6.4 1.93 0 3.634 1.122 4.641 2.826a3.932 3.932 0 011.759-.426zm0-.8a2.403 2.403 0 01-2.4-2.4C9.6 5.077 10.676 4 12 4c1.322 0 2.4 1.077 2.4 2.4S13.322 8.8 12 8.8zm0-3.2a.8.8 0 11-.002 1.602A.8.8 0 0112 5.6zm-6.4.8a3.203 3.203 0 01-3.2-3.2C2.4 1.435 3.834 0 5.6 0c1.764 0 3.2 1.435 3.2 3.2 0 1.765-1.436 3.2-3.2 3.2zm0-4.8c.882 0 1.6.718 1.6 1.6 0 .882-.718 1.6-1.6 1.6-.883 0-1.6-.718-1.6-1.6 0-.882.717-1.6 1.6-1.6\"/>",
    "attrs": {
      "width": "16",
      "height": "15",
      "viewBox": "0 0 16 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});