define("ember-svg-jar/inlined/alert-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M2.705 13.5a7.006 7.006 0 00.896-3.446V9c0-1.985 1.615-3.6 3.6-3.6 1.986 0 3.6 1.615 3.6 3.6v1.054c0 1.227.312 2.406.897 3.446H2.705zm4.496 2.7a.9.9 0 01-.9-.9h1.8a.9.9 0 01-.9.9zm0-14.4a.9.9 0 110 1.801.9.9 0 010-1.8zm6.937 11.964a5.25 5.25 0 01-1.537-3.71v-.866c0-2.178-1.249-4.15-3.12-5.055a2.67 2.67 0 00.42-1.433c0-1.488-1.211-2.7-2.7-2.7a2.703 2.703 0 00-2.7 2.7c0 .52.156 1.002.412 1.415A5.403 5.403 0 001.8 9v1.054a5.245 5.245 0 01-1.535 3.71H.264c-.567.567-.166 1.536.637 1.536h3.6c0 1.489 1.21 2.7 2.7 2.7 1.488 0 2.7-1.211 2.7-2.7h3.6c.801 0 1.203-.969.636-1.536z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "18",
      "viewBox": "0 0 15 18"
    }
  };
});