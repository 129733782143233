define("ember-svg-jar/inlined/envelope-open-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M10.906 1.625a270.16 270.16 0 014.531 3.656c.376.313.563.698.563 1.157V14.5c0 .417-.146.77-.438 1.063A1.447 1.447 0 0114.5 16h-13c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 010 14.5V6.437c0-.458.187-.843.563-1.156a1202.93 1202.93 0 014.53-3.656l.313-.281c.5-.417.896-.709 1.188-.875C7.094.156 7.562 0 8 0c.438 0 .906.156 1.406.469.292.187.688.479 1.188.875.146.146.25.24.312.281zM14.5 15a.487.487 0 00.36-.14.487.487 0 00.14-.36V6.437a.473.473 0 00-.188-.375 263.08 263.08 0 00-4.53-3.656l-.313-.25c-.417-.354-.74-.604-.969-.75C8.604 1.136 8.27 1 8 1s-.604.135-1 .406c-.23.146-.552.396-.969.75l-.312.25a673.568 673.568 0 00-4.532 3.656.473.473 0 00-.187.375V14.5c0 .146.047.266.14.36.094.093.214.14.36.14h13zm-.438-6.969l.157.219a.364.364 0 01.078.266.446.446 0 01-.14.265l-3.563 2.844c-.5.417-.896.719-1.188.906-.52.313-.99.469-1.406.469-.417 0-.885-.156-1.406-.469-.313-.187-.709-.49-1.188-.906L1.844 8.781a.446.446 0 01-.14-.265.364.364 0 01.077-.266l.157-.219a.397.397 0 01.25-.11.389.389 0 01.28.079L6 10.844c.417.354.75.604 1 .75.396.27.73.406 1 .406s.604-.135 1-.406c.25-.146.583-.396 1-.75L13.531 8a.389.389 0 01.281-.078c.105.01.188.047.25.11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});