define("ember-svg-jar/inlined/lightbulb-exclamation-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.875 9.688a.937.937 0 100 1.874.937.937 0 000-1.874zm-.643-1.231c.01.165.147.293.311.293h.663a.312.312 0 00.312-.293l.273-4.375a.312.312 0 00-.311-.332H6.27a.312.312 0 00-.312.332l.274 4.375zM6.875 0C2.854 0-.005 3.263 0 6.884a6.836 6.836 0 001.702 4.514C2.704 12.54 3.666 14.402 3.75 15l.002 2.937c0 .123.037.243.105.345l.957 1.44a.625.625 0 00.52.278h3.08c.21 0 .406-.105.521-.279l.958-1.439a.628.628 0 00.104-.345L10 15c.088-.614 1.054-2.468 2.048-3.602a6.837 6.837 0 001.702-4.523A6.875 6.875 0 006.875 0zm1.873 17.746L8.08 18.75H5.67l-.668-1.004V17.5h3.746v.246zm.001-1.496H5L4.998 15H8.75l-.002 1.25zm2.36-5.677c-.547.624-1.42 1.88-1.976 3.177H4.618c-.557-1.298-1.43-2.553-1.976-3.177a5.604 5.604 0 01-1.39-3.698C1.245 3.868 3.61 1.25 6.875 1.25A5.631 5.631 0 0112.5 6.875a5.608 5.608 0 01-1.391 3.698z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "20",
      "viewBox": "0 0 14 20"
    }
  };
});