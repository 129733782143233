define("ember-svg-jar/inlined/micro-plus-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 4h4v2H6v4H4V6H0V4h4V0h2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10"
    }
  };
});