define("ember-svg-jar/inlined/ascend-sort-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.732 12.857l-3 3.017a.429.429 0 01-.607 0l-3-3.017a.429.429 0 010-.606L.379 12a.429.429 0 01.606 0l1.837 1.854V.43c0-.237.192-.429.429-.429h.357c.237 0 .429.192.429.429v13.424L5.873 12a.429.429 0 01.606 0l.253.252a.429.429 0 010 .606zm3.589-7.87h2.198l.553 1.583c.06.172.223.287.405.287h.412a.429.429 0 00.404-.574l-2.159-6A.429.429 0 0011.731 0h-.605a.429.429 0 00-.403.283l-2.159 6a.43.43 0 00.404.574h.402a.429.429 0 00.405-.289l.546-1.58zm.363-1.11l.646-1.83c.03-.09.063-.2.095-.312.03.112.063.223.094.315l.637 1.828h-1.472zm-.124 10.977c.171-.216-.042.083 3.318-4.77a.429.429 0 00.076-.244v-.269a.429.429 0 00-.428-.428H9.46a.429.429 0 00-.428.428v.289c0 .237.192.43.43.43 2.923 0 2.722 0 2.794-.002-.106.137.163-.254-3.314 4.761a.43.43 0 00-.076.245v.277c0 .237.192.429.428.429h4.269a.429.429 0 00.428-.429v-.289a.429.429 0 00-.428-.428H10.56z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "16",
      "viewBox": "0 0 15 16"
    }
  };
});