define("ember-svg-jar/inlined/profile-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M12.8 14.4H1.6v-1.895c3.825-1.757 7.327-1.758 11.2.002V14.4zm0-12.8v9.169c-3.824-1.571-7.415-1.572-11.2-.004V1.6h11.2zM0 0v16h14.4V0H0zm7.2 4.8c.44 0 .8.36.8.8 0 .44-.36.8-.8.8-.44 0-.8-.36-.8-.8 0-.44.36-.8.8-.8m0 3.2c1.323 0 2.4-1.077 2.4-2.4S8.523 3.2 7.2 3.2a2.403 2.403 0 00-2.4 2.4C4.8 6.923 5.877 8 7.2 8\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "16",
      "viewBox": "0 0 15 16"
    }
  };
});