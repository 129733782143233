define("ember-svg-jar/inlined/gear-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.014 9.396l-1.052-.607a6.296 6.296 0 000-1.578l1.052-.607a.772.772 0 00.356-.887 7.986 7.986 0 00-1.856-3.213.774.774 0 00-.95-.138l-1.05.606a6.262 6.262 0 00-1.366-.79V.97a.775.775 0 00-.596-.753 8.056 8.056 0 00-3.702 0 .775.775 0 00-.597.753v1.214c-.486.204-.945.47-1.365.79l-1.05-.607a.774.774 0 00-.95.138A7.987 7.987 0 00.032 5.717a.772.772 0 00.356.887l1.052.607a6.296 6.296 0 000 1.578l-1.052.607a.772.772 0 00-.356.887 7.987 7.987 0 001.856 3.213c.246.26.639.317.95.138l1.05-.606c.42.32.879.585 1.365.79v1.213c0 .359.247.67.597.753a8.056 8.056 0 003.702 0 .775.775 0 00.596-.753v-1.214c.487-.204.946-.47 1.366-.79l1.05.607c.31.18.704.122.95-.138a7.987 7.987 0 001.856-3.213.772.772 0 00-.356-.887zm-2.113 3.24l-1.493-.862c-.871.744-1.18.932-2.292 1.325v1.725a7.005 7.005 0 01-2.83 0v-1.725c-1.085-.384-1.399-.562-2.292-1.325l-1.494.863a6.968 6.968 0 01-1.417-2.45l1.495-.863c-.211-1.143-.211-1.505 0-2.648l-1.495-.862A6.973 6.973 0 012.5 3.363l1.494.863c.883-.756 1.196-.937 2.292-1.325V1.176a6.993 6.993 0 012.83 0v1.725c1.096.388 1.408.57 2.292 1.325l1.493-.863a6.972 6.972 0 011.417 2.45l-1.494.863c.21 1.143.21 1.505 0 2.648l1.494.862a6.968 6.968 0 01-1.417 2.45zm-5.2-7.733A3.1 3.1 0 004.604 8a3.1 3.1 0 003.097 3.097A3.1 3.1 0 0010.798 8 3.1 3.1 0 007.7 4.903zm0 5.162A2.067 2.067 0 015.636 8c0-1.138.926-2.065 2.065-2.065 1.138 0 2.064.927 2.064 2.065a2.067 2.067 0 01-2.064 2.065z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});