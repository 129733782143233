define("ember-svg-jar/inlined/warning-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M17.82 13.768c.167.334.22.673.157 1.017a1.5 1.5 0 01-.5.875c-.272.24-.595.36-.97.36H1.495c-.375 0-.698-.12-.969-.36a1.5 1.5 0 01-.5-.875 1.587 1.587 0 01.156-1.017L7.688.758c.187-.333.453-.557.797-.672a1.591 1.591 0 011.032 0c.344.115.61.339.798.672l7.505 13.01zm-.875.5a.505.505 0 01-.016.501.455.455 0 01-.422.25H1.495a.455.455 0 01-.422-.25.505.505 0 01-.015-.5l7.505-13.01a.49.49 0 01.438-.25.49.49 0 01.438.25l7.506 13.01zM8.657 5.013h.688c.125 0 .224.036.297.11a.277.277 0 01.078.265l-.218 5.254a.362.362 0 01-.11.266.362.362 0 01-.266.11h-.25a.362.362 0 01-.266-.11.329.329 0 01-.11-.235l-.218-5.285a.277.277 0 01.078-.266.401.401 0 01.297-.11zm.344 6.63a.851.851 0 01.876.875c0 .25-.084.459-.25.626a.849.849 0 01-.626.25.849.849 0 01-.625-.25.849.849 0 01-.25-.626c0-.25.083-.458.25-.625a.849.849 0 01.625-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "17",
      "viewBox": "0 0 18 17"
    }
  };
});