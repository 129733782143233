define("ember-svg-jar/inlined/lock-screen-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M15.3 13.6V3.4H9.297v1.7H13.6v6.8H3.4v-1.7H1.7v3.4h5.95v1.7h-1.7V17h5.1v-1.7h-1.7v-1.7h5.95zM1.7 6.8h4.25V5.1H1.7v1.7zm.85-3.825c0-.703.572-1.275 1.275-1.275S5.1 2.272 5.1 2.975V3.4H2.55v-.425zm5.1.425H6.8v-.425A2.979 2.979 0 003.825 0 2.98 2.98 0 00.85 2.975V3.4H0v5.1h7.65V3.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "17",
      "viewBox": "0 0 16 17"
    }
  };
});