define("ember-svg-jar/inlined/minus-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.79 9.653a.437.437 0 01-.435-.435v-.436c0-.24.196-.435.435-.435h8.42c.24 0 .435.196.435.435v.436c0 .24-.196.435-.435.435H4.79zM18 9c0 4.972-4.028 9-9 9s-9-4.028-9-9 4.028-9 9-9 9 4.028 9 9zm-1.161 0c0-4.351-3.531-7.839-7.839-7.839-4.351 0-7.839 3.531-7.839 7.839 0 4.351 3.531 7.839 7.839 7.839 4.351 0 7.839-3.531 7.839-7.839z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
});