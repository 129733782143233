define("ember-svg-jar/inlined/file-upload-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.854.5L13.6 5.246V17.5H0V.5zM7.652 2.2H1.7v13.6h10.205V6.452H7.652zM6.8 8.152l3.405 3.065-1.138 1.26-1.415-1.274v3.763h-1.7v-3.763l-1.419 1.274-1.138-1.26zm2.552-4.75v1.346H10.7z\"/>",
    "attrs": {
      "height": "18",
      "viewBox": "0 0 14 18",
      "width": "14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});