define("ember-svg-jar/inlined/pencil-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M3.262 13.03l1.708 1.708-2.278.57.57-2.278zM13.29 1.94l2.769 2.77-1.17 1.168-2.767-2.77L13.29 1.94zm-9.176 9.176l6.623-6.623 2.768 2.77-6.62 6.622-2.77-2.77zm13.33-5.021c.37-.37.573-.862.573-1.386 0-.523-.203-1.015-.574-1.384L14.674.555c-.74-.74-2.031-.74-2.769 0l-10.06 10.06L0 18l7.385-1.847 10.06-10.058z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
});