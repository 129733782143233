define("ember-svg-jar/inlined/trash-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M13.5 2.5h3.438c.26 0 .481.091.664.273a.904.904 0 01.273.664v.079c0 .052-.026.104-.078.156-.052.052-.104.078-.156.078h-1.016v14.375c0 .52-.182.964-.547 1.328A1.808 1.808 0 0114.75 20H3.5c-.52 0-.964-.182-1.328-.547a1.808 1.808 0 01-.547-1.328V3.75H.609c-.052 0-.104-.026-.156-.078-.052-.052-.078-.104-.078-.156v-.079c0-.26.091-.481.273-.664a.904.904 0 01.665-.273H4.75L6.078.742C6.443.247 6.938 0 7.562 0h3.126c.625 0 1.12.247 1.484.742L13.5 2.5zM7.562 1.25a.63.63 0 00-.507.234L6.313 2.5h5.625l-.743-1.016a.63.63 0 00-.508-.234H7.563zm7.813 16.875V3.75h-12.5v14.375c0 .182.059.332.176.45a.608.608 0 00.449.175h11.25a.608.608 0 00.45-.176.608.608 0 00.175-.449zM8.5 16.406V6.094c0-.13.046-.241.137-.332a.452.452 0 01.332-.137h.312c.13 0 .241.046.332.137a.452.452 0 01.137.332v10.312c0 .13-.046.241-.137.332a.452.452 0 01-.332.137H8.97a.452.452 0 01-.332-.137.452.452 0 01-.137-.332zm-3.125 0V6.094c0-.13.046-.241.137-.332a.452.452 0 01.332-.137h.312c.13 0 .241.046.332.137a.452.452 0 01.137.332v10.312c0 .13-.046.241-.137.332a.452.452 0 01-.332.137h-.312a.452.452 0 01-.332-.137.452.452 0 01-.137-.332zm6.25 0V6.094c0-.13.046-.241.137-.332a.452.452 0 01.332-.137h.312c.13 0 .241.046.332.137a.452.452 0 01.137.332v10.312c0 .13-.046.241-.137.332a.452.452 0 01-.332.137h-.312a.452.452 0 01-.332-.137.452.452 0 01-.137-.332z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "20",
      "viewBox": "0 0 18 20"
    }
  };
});