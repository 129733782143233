define("ember-svg-jar/inlined/megaphone-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M13.67 11.027l-.428-.142c-.63-.493-1.342-2.164-1.342-4.51 0-2.136.591-3.722 1.172-4.36l.633-.281c.673.171 1.595 1.968 1.595 4.64 0 2.719-.955 4.535-1.63 4.653M4.25 15.3a.85.85 0 01-.85-.85V10.2h1.7v4.25a.85.85 0 01-.85.85M3.4 5.1h2.19c.359 0 .707-.074 1.036-.22l4.035-1.794c-.293.95-.461 2.067-.461 3.289 0 1.4.218 2.665.596 3.695L6.48 8.63a2.525 2.525 0 00-.806-.131H3.4a1.701 1.701 0 010-3.4M13.6 0C15.539 0 17 2.74 17 6.375s-1.461 6.375-3.4 6.375c-.389 0-.757-.114-1.1-.32l-5.7-1.9v3.92A2.552 2.552 0 014.25 17a2.553 2.553 0 01-2.55-2.55V9.727A3.39 3.39 0 010 6.8c0-1.875 1.526-3.4 3.4-3.4h2.19a.83.83 0 00.344-.074l6.237-2.77C12.6.2 13.083 0 13.6 0zM7.65 14.45v-1.7h1.7v1.7h-1.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17"
    }
  };
});