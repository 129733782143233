define("ember-svg-jar/inlined/user-message-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M1.914 16.2A3.605 3.605 0 015.4 13.5a3.607 3.607 0 013.486 2.7H1.914zM5.4 11.7c2.977 0 5.4 2.422 5.4 5.4v.9H0v-.9c0-2.978 2.422-5.4 5.4-5.4zm0-4.5a.9.9 0 11-.9.9c0-.496.404-.9.9-.9zm0 3.6a2.703 2.703 0 01-2.7-2.7c0-1.489 1.211-2.7 2.7-2.7 1.488 0 2.7 1.211 2.7 2.7 0 1.488-1.212 2.7-2.7 2.7zm10.8-6.3h-1.8a.9.9 0 00-.54.18L11.7 6.3v-.9a.9.9 0 00-.9-.9V1.8h5.4v2.7zm.9-4.5H9.9a.9.9 0 00-.9.9v4.5a.9.9 0 00.9.9v1.8a.9.9 0 001.44.72L14.7 6.3h2.4a.9.9 0 00.9-.9V.9a.9.9 0 00-.9-.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
});