define("ember-svg-jar/inlined/vertical-ellipsis-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.684 6.316c.932 0 1.684.752 1.684 1.684 0 .932-.752 1.684-1.684 1.684A1.682 1.682 0 010 8c0-.932.753-1.684 1.684-1.684zM0 1.684c0 .932.753 1.684 1.684 1.684.932 0 1.684-.752 1.684-1.684C3.368.753 2.616 0 1.684 0 .753 0 0 .753 0 1.684zm0 12.632C0 15.247.753 16 1.684 16c.932 0 1.684-.753 1.684-1.684 0-.932-.752-1.684-1.684-1.684-.931 0-1.684.752-1.684 1.684z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "4",
      "height": "16",
      "viewBox": "0 0 4 16"
    }
  };
});