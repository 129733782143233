define("ember-svg-jar/inlined/arrow-down-to-bottom-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M10.906 7.25l-4.625 4.625A.389.389 0 016 12a.457.457 0 01-.281-.094L1.125 7.25A.373.373 0 011 6.984c0-.093.031-.182.094-.265l.25-.219a.297.297 0 01.25-.125c.104 0 .198.042.281.125l3.594 3.625V.375c0-.104.036-.193.11-.266A.362.362 0 015.843 0h.312c.104 0 .193.036.266.11.073.072.11.16.11.265v9.75L10.124 6.5a.389.389 0 01.281-.125c.104 0 .188.042.25.125l.25.219a.435.435 0 01.094.265.435.435 0 01-.094.266zM12 13.625a.362.362 0 01-.11.266.362.362 0 01-.265.109H.375a.362.362 0 01-.266-.11.362.362 0 01-.109-.265v-.25c0-.104.036-.193.11-.266A.362.362 0 01.374 13h11.25c.104 0 .193.036.266.11.073.072.109.16.109.265v.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "14",
      "viewBox": "0 0 12 14"
    }
  };
});