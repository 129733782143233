define("ember-svg-jar/inlined/right-long-arrow-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M9.254.11L9.063.3a.26.26 0 00-.11.22.34.34 0 00.11.246l2.296 2.27H1.079a.316.316 0 00-.233.095.316.316 0 00-.096.232v.274a.316.316 0 00.096.232.316.316 0 00.232.096H11.36l-2.296 2.27a.34.34 0 00-.11.245.26.26 0 00.11.22l.19.19a.327.327 0 00.233.11.327.327 0 00.233-.11l3.17-3.17A.26.26 0 0013 3.5a.26.26 0 00-.11-.219L9.72.11a.326.326 0 00-.233-.109.321.321 0 00-.232.11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "7",
      "viewBox": "0 0 13 7"
    }
  };
});