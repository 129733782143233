define("ember-svg-jar/inlined/trash-down-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13 17c0 .552-.45 1-1 1H4c-.551 0-1-.448-1-1V6h10v11zM6 4h4V2H6v2zm10 0h-4V2c0-1.103-.897-2-2-2H6C4.897 0 4 .897 4 2v2H0v2h1v11c0 1.654 1.346 3 3 3h8c1.654 0 3-1.346 3-3V6h1V4zM5 16h2V8H5v8zm4 0h2V8H9v8zM24.769 15.329l1.269-1.384 1.015.907L24.166 18 21 14.876l.968-.955 1.432 1.413V8h1.369v7.329z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "27",
      "height": "20",
      "viewBox": "0 0 27 20"
    }
  };
});