define("ember-svg-jar/inlined/flag-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.76 2.333c-1.768 0-3.184-1.083-5.369-1.083-.89 0-1.677.166-2.405.452A1.5 1.5 0 101 2.914v12.711c0 .207.168.375.375.375h.25A.375.375 0 002 15.625v-3.8c.982-.454 2.252-.908 4.24-.908 1.768 0 3.184 1.083 5.369 1.083 1.603 0 2.873-.539 4.062-1.342a.749.749 0 00.329-.62V1.86a.75.75 0 00-1.066-.68c-1.15.534-2.696 1.153-4.173 1.153zM1.5.875a.626.626 0 11-.001 1.251A.626.626 0 011.5.875zM15 9.917c-.722.521-2.017 1.083-3.391 1.083-1.988 0-3.383-1.083-5.37-1.083-1.438 0-3.196.318-4.239.812V3.333c.722-.521 2.017-1.083 3.391-1.083 1.988 0 3.383 1.083 5.37 1.083 1.435 0 3.194-.588 4.239-1.083v7.667z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});