define("ember-svg-jar/inlined/building-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M0 0v16h16V0H0zm1.6 1.6h12.8v12.8h-4V9.6H5.6v4.8h-4V1.6zm5.6 12.8v-3.2h1.6v3.2H7.2zm-4-9.6V3.2h1.6v1.6H3.2zm4 0V3.2h1.6v1.6H7.2zm4 0V3.2h1.6v1.6h-1.6zM3.2 8V6.4h1.6V8H3.2zm4 0V6.4h1.6V8H7.2zm4 0V6.4h1.6V8h-1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});