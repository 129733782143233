define("ember-svg-jar/inlined/dollar-sign-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.--><path d=\"M184 24c0-13.3-10.7-24-24-24s-24 10.7-24 24v47.7c-3.1.1-6.2.3-9.3.6-23.2 1.9-47.2 7.4-67.2 20.1-20.8 13.2-36 33.6-41.5 61.8-3.9 20.3-2 38.8 6.1 55.2 8 16 20.9 27.6 34.8 36.2 26.2 16.2 61.9 25.3 92.9 33.2l2.3.6c33.9 8.6 62.6 16.1 81.7 28 9 5.6 14.3 11.2 17.1 16.9 2.7 5.3 4.2 12.8 2 24.5-2.9 14.7-13.4 26.9-34.5 34.9-21.6 8.2-52 10.9-87.6 5.9-22.6-3.3-61.8-12.7-83-22.1-12.1-5.4-26.3.1-31.7 12.2s.1 26.3 12.2 31.7c26.7 11.8 70.8 22 95.7 25.6h.1c3.3.5 6.6.9 9.9 1.2V488c0 13.3 10.7 24 24 24s24-10.7 24-24v-48.6c19.3-1.3 37.4-4.9 53.5-11 31.9-12.1 57.7-35.2 64.5-70.6 3.9-20.3 2-38.8-6.1-55.2-8-16-20.9-27.6-34.8-36.2-26.2-16.2-61.9-25.3-92.9-33.2l-2.3-.6c-33.9-8.6-62.6-16.1-81.7-28-9-5.6-14.3-11.2-17.1-16.9-2.7-5.3-4.2-12.8-2-24.5 2.9-14.8 10.1-24 20.2-30.4 10.9-6.9 26.3-11.2 45.3-12.7 38.3-3.1 83.1 5.3 113.9 12.5 12.9 3.1 25.8-4.9 28.9-17.8s-4.9-25.8-17.8-28.9c-18.7-4.4-44.2-9.7-71.5-12.5V24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "1em",
      "viewBox": "0 0 320 512"
    }
  };
});