define("ember-svg-jar/inlined/print-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.813 6.25h-.438V3.987a.876.876 0 00-.256-.618l-2.113-2.113A.875.875 0 009.387 1H4.458c-.46 0-.833.392-.833.875V6.25h-.437C1.979 6.25 1 7.23 1 8.438v2.624c0 .242.196.438.438.438h2.187v3.063c0 .241.196.437.438.437h7.875a.437.437 0 00.437-.438V11.5h2.188a.437.437 0 00.437-.438V8.438c0-1.209-.98-2.188-2.187-2.188zM9.75 2.237l1.388 1.388H9.75zm-5.247-.362h4.372v1.75c0 .483.392.875.875.875h1.75v1.75h-7zm6.997 12.25h-7V11.5h7zm2.625-3.5H1.875V8.437c0-.723.589-1.312 1.313-1.312h9.624c.724 0 1.313.589 1.313 1.313zm-2.188-2.406a.656.656 0 100 1.312.656.656 0 000-1.312z\"/>",
    "attrs": {
      "height": "16",
      "viewBox": "0 0 15 15",
      "width": "16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});