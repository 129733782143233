define("ember-svg-jar/inlined/mini-lock-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#EC3636\" fill-rule=\"evenodd\" d=\"M2 8h4V6H2v2zm1-5a1.001 1.001 0 012 0v1H3V3zm4 1V3c0-1.654-1.345-3-3-3-1.654 0-3 1.346-3 3v1H0v6h8V4H7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "8",
      "height": "10",
      "viewBox": "0 0 8 10"
    }
  };
});