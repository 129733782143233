define("ember-svg-jar/inlined/copy-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><path id=\"id-0a\" d=\"M0 0h12v13.994H0z\"/><path id=\"id-2c\" d=\"M.003 0h11.994v13.994H.003z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(2 1)\"><mask id=\"id-1b\" fill=\"#fff\"><use xlink:href=\"#id-0a\"/></mask><path mask=\"url(#id-1b)\" d=\"M0 0h12v13.994H0z\"/></g><g transform=\"translate(2 1)\"><mask id=\"id-3d\" fill=\"#fff\"><use xlink:href=\"#id-2c\"/></mask><path d=\"M11.621 1.802L10.232.384A1.226 1.226 0 009.323 0H4.715C4.005 0 3.43.587 3.43 1.312v1.312H1.288c-.71 0-1.285.587-1.285 1.312v8.746c0 .725.575 1.312 1.285 1.312h5.997c.71 0 1.285-.587 1.285-1.312V11.37h2.142c.71 0 1.285-.587 1.285-1.312V2.73c0-.362-.125-.672-.376-.928zM9.427.888c.077.02.143.058.2.115l1.388 1.418a.429.429 0 01.113.203h-1.7V.888zM7.714 12.682a.433.433 0 01-.429.438H1.288a.433.433 0 01-.429-.438V3.936c0-.241.192-.437.429-.437H3.43v6.56c0 .724.575 1.311 1.285 1.311h2.999v1.312zm3.427-2.624a.433.433 0 01-.429.438H4.715a.433.433 0 01-.429-.438V1.312c0-.241.193-.437.429-.437H8.57v1.968c0 .36.29.656.643.656h1.928v6.56z\" fill=\"#000\" mask=\"url(#id-3d)\"/></g></g>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});