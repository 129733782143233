define("ember-svg-jar/inlined/bullhorn-thin-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#212631\" fill-rule=\"evenodd\" d=\"M14 5.5c.22 0 .41.08.571.241a.78.78 0 01.242.572c0 .22-.08.41-.242.57a.78.78 0 01-.571.242v4.063c0 .22-.08.41-.241.57a.78.78 0 01-.572.242 14.709 14.709 0 00-3.224-2.158C8.473 9.13 7 8.767 5.545 8.75c-.152.085-.258.233-.317.444-.06.212-.047.352.038.42a.814.814 0 01.203.469.834.834 0 01-.102.495c-.068.119-.06.224.026.318.084.093.304.25.66.47a.868.868 0 01.343.469.715.715 0 01-.064.571c-.17.39-.427.69-.774.902a2.13 2.13 0 01-1.13.317c-.305 0-.601-.06-.889-.178a2.312 2.312 0 01-.762-.508.764.764 0 01-.203-.33l-.178-.533a21.797 21.797 0 01-.457-1.549 6.422 6.422 0 01-.101-1.777h-.432c-.338 0-.626-.118-.863-.355a1.175 1.175 0 01-.355-.864V5.094c0-.339.118-.627.355-.864s.525-.355.863-.355H5.47c1.49 0 2.979-.364 4.468-1.092a14.46 14.46 0 003.25-2.158c.22 0 .411.08.572.241a.78.78 0 01.241.571V5.5zm-8.379 6.55c-.474-.287-.795-.55-.965-.786-.22-.339-.22-.703 0-1.092a1.096 1.096 0 01-.266-.648c-.026-.262.02-.52.14-.774H2.65a5.195 5.195 0 00.102 1.6c.068.338.203.829.406 1.472l.178.533c.22.22.482.36.787.42.305.059.592.029.863-.09.271-.118.483-.33.635-.634zM1 7.532c0 .119.038.216.114.292a.395.395 0 00.292.114h4.469v-3.25H1.406a.395.395 0 00-.292.115.395.395 0 00-.114.292V7.53zm12.188 3.403V1.69a16.558 16.558 0 01-2.895 1.829c-1.202.575-2.404.939-3.605 1.091v3.403c1.201.152 2.403.516 3.605 1.091a15.849 15.849 0 012.895 1.829z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "14",
      "viewBox": "0 0 15 14"
    }
  };
});