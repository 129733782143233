define("ember-svg-jar/inlined/anchor-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14.302 13.09a.902.902 0 01-.073.938C12.372 16.59 9.875 18 7.199 18c-2.675 0-5.172-1.41-7.028-3.972A.902.902 0 01.9 12.6h2.7a.9.9 0 010 1.8h-.71c1.03.941 2.192 1.525 3.409 1.72V8.1H3.6a.9.9 0 010-1.8h2.7V5.234A2.695 2.695 0 014.5 2.7C4.5 1.211 5.71 0 7.2 0c1.488 0 2.7 1.211 2.7 2.7 0 1.172-.754 2.16-1.8 2.534V6.3h2.7a.9.9 0 010 1.8H8.1v8.02c1.217-.195 2.38-.779 3.408-1.72H10.8a.9.9 0 010-1.8h2.7c.338 0 .648.19.802.49zM7.2 1.8c-.496 0-.9.404-.9.9s.404.9.9.9.9-.404.9-.9-.404-.9-.9-.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "18",
      "viewBox": "0 0 15 18"
    }
  };
});