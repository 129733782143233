define("ember-svg-jar/inlined/coin-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8 5.6a.8.8 0 01.8.8h1.6c0-1.04-.669-1.92-1.598-2.252V3.2h-1.6v.947A2.395 2.395 0 005.6 6.4c0 1.323 1.077 2.4 2.4 2.4a.8.8 0 11-.8.8H5.6c0 1.042.671 1.921 1.6 2.252v.948h1.6v-.948c.93-.331 1.6-1.21 1.6-2.252 0-1.323-1.077-2.4-2.4-2.4a.8.8 0 010-1.6m0 8.8A6.407 6.407 0 011.6 8c0-3.53 2.871-6.4 6.4-6.4 3.529 0 6.4 2.87 6.4 6.4 0 3.529-2.871 6.4-6.4 6.4M8 0C3.589 0 0 3.588 0 8c0 4.411 3.589 8 8 8s8-3.589 8-8c0-4.412-3.589-8-8-8\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});