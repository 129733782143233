define("ember-svg-jar/inlined/hyperlink-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8.586 6.164c.456.456.775.989.957 1.6.182.61.214 1.226.096 1.845a3.703 3.703 0 01-.78 1.668c-.09.11-.483.51-1.175 1.203l-1.149 1.15a3.755 3.755 0 01-1.723.998 3.795 3.795 0 01-1.968 0 3.755 3.755 0 01-1.723-.998 3.755 3.755 0 01-.998-1.723 3.795 3.795 0 010-1.969c.173-.656.506-1.23.998-1.722l1.723-1.723c.11-.11.232-.137.369-.082a.3.3 0 01.205.3c0 .347.046.694.137 1.04.018.11-.019.21-.11.3L2.215 9.31a2.149 2.149 0 00-.684 1.613c0 .638.224 1.18.67 1.627.447.446.99.67 1.627.67.638 0 1.176-.228 1.613-.684l2.079-2.05c.437-.438.656-.976.656-1.614 0-.638-.219-1.185-.656-1.64a2.165 2.165 0 00-.63-.438c-.145-.073-.209-.182-.19-.328a1.38 1.38 0 01.41-.848l.109-.11c.127-.109.255-.127.383-.054.364.182.692.42.984.711zm4.293-4.293c.492.492.825 1.066.998 1.723a3.795 3.795 0 010 1.969 3.755 3.755 0 01-.998 1.722l-1.723 1.723c-.11.11-.232.137-.369.082a.3.3 0 01-.205-.3c0-.347-.046-.694-.137-1.04-.018-.11.019-.21.11-.3l1.23-1.259c.456-.437.684-.975.684-1.613s-.224-1.18-.67-1.627a2.215 2.215 0 00-1.627-.67c-.638 0-1.176.228-1.613.684L6.48 5.015a2.197 2.197 0 00-.656 1.614c0 .638.219 1.185.656 1.64.183.183.392.329.63.438.145.073.209.182.19.328a1.38 1.38 0 01-.41.848l-.109.11c-.127.109-.255.127-.383.054a3.766 3.766 0 01-.984-.711 3.745 3.745 0 01-.957-1.6 3.841 3.841 0 01-.096-1.845c.119-.62.379-1.176.78-1.668.09-.11.483-.51 1.175-1.203l1.149-1.15A3.755 3.755 0 019.188.872a3.795 3.795 0 011.968 0c.657.173 1.23.506 1.723.998z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "15",
      "viewBox": "0 0 14 15"
    }
  };
});