define("ember-svg-jar/inlined/percent-thin-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.001 6A3 3 0 103-.002 3 3 0 003 6zm0-4.875c1.034 0 1.875.84 1.875 1.875 0 1.034-.84 1.875-1.875 1.875A1.876 1.876 0 011.126 3c0-1.034.84-1.875 1.875-1.875zm6 6.875a3 3 0 10.002 6.002A3 3 0 009 8zm0 4.875A1.876 1.876 0 017.126 11c0-1.034.84-1.875 1.875-1.875 1.034 0 1.875.84 1.875 1.875 0 1.034-.84 1.875-1.875 1.875zM10.87 0h.944c.156 0 .243.178.15.3L1.282 13.925a.186.186 0 01-.15.075H.19a.187.187 0 01-.15-.3L10.719.075A.186.186 0 0110.87 0z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "12",
      "height": "14",
      "viewBox": "0 0 12 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});