define("ember-svg-jar/inlined/jump-backward-solid-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><clipPath id=\"id-5b\"><use xlink:href=\"#id-4a\"/></clipPath><path transform=\"translate(0 -2)\" id=\"id-4a\" d=\"M.375 13.998h.25A.376.376 0 001 13.624V8.697a.86.86 0 00.093.09l6.264 4.98c.644.538 1.64.088 1.64-.768v-3.68l5.359 4.448c.643.538 1.64.088 1.64-.768V3c0-.856-.997-1.306-1.64-.768L8.998 6.716V3.004c0-.856-.997-1.305-1.64-.768L1.092 7.25A.86.86 0 001 7.34V2.377a.376.376 0 00-.375-.374h-.25A.376.376 0 000 2.376v11.248c0 .206.169.374.375.374z\"/></defs><use xlink:href=\"#id-4a\" stroke-width=\"2\" clip-path=\"url(#id-5b)\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "16",
      "height": "12",
      "viewBox": "0 0 16 12"
    }
  };
});