define("ember-svg-jar/inlined/critical-solid-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9 .281A8.717 8.717 0 00.281 9 8.717 8.717 0 009 17.719 8.717 8.717 0 0017.719 9 8.717 8.717 0 009 .281zm4.275 11.008a.422.422 0 010 .597l-1.392 1.389a.422.422 0 01-.598 0L9 10.969l-2.289 2.306a.422.422 0 01-.597 0l-1.389-1.392a.422.422 0 010-.598L7.031 9 4.725 6.711a.422.422 0 010-.597L6.117 4.72a.422.422 0 01.598 0L9 7.031l2.289-2.306a.422.422 0 01.597 0l1.392 1.392a.422.422 0 010 .598L10.968 9l2.307 2.289z\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});