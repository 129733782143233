define("ember-svg-jar/inlined/table-cells-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.--><path d=\"M360 80v88h104V96c0-8.8-7.2-16-16-16h-88zm-48 0H200v88h112V80zm-160 0H64c-8.8 0-16 7.2-16 16v72h104V80zM48 216v80h104v-80H48zm0 128v72c0 8.8 7.2 16 16 16h88v-88H48zm152 88h112v-88H200v88zm160 0h88c8.8 0 16-7.2 16-16v-72H360v88zm104-136v-80H360v80h104zM0 96c0-35.3 28.7-64 64-64h384c35.3 0 64 28.7 64 64v320c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm200 200h112v-80H200v80z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "1em",
      "viewBox": "0 0 512 512"
    }
  };
});