define("ember-svg-jar/inlined/heading-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8.036 1.286h.983v4.017H2.98V1.286h.983a.321.321 0 00.322-.322V.321A.321.321 0 003.964 0H.321A.321.321 0 000 .321v.643c0 .178.144.322.321.322h.96v9.428h-.96a.321.321 0 00-.321.322v.643c0 .177.144.321.321.321h3.643a.321.321 0 00.322-.321v-.643a.321.321 0 00-.322-.322h-.983V6.697H9.02v4.017h-.983a.321.321 0 00-.322.322v.643c0 .177.144.321.322.321h3.643a.321.321 0 00.321-.321v-.643a.321.321 0 00-.321-.322h-.96V1.286h.96A.321.321 0 0012 .964V.321A.321.321 0 0011.679 0H8.036a.321.321 0 00-.322.321v.643c0 .178.144.322.322.322z\"/>",
    "attrs": {
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});