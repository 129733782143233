define("ember-svg-jar/inlined/door-lock-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M0 0h12v2.401h-1.6v-.8H1.6v12.798h8.8v-.8H12V16H0V0zm3.2 8.8V7.2h1.6v1.6H3.2zm8 1.6V8.8h3.2v1.6h-3.2zm.8-4a.8.8 0 011.6 0v.8H12v-.8zm3.2.8v-.8c0-1.324-1.076-2.4-2.4-2.4a2.402 2.402 0 00-2.4 2.4v.8h-.8V12H16V7.2h-.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});