define("ember-svg-jar/inlined/key-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.188.875A3.938 3.938 0 117.72 8.467L6.562 9.625H5.25v1.75H3.5v1.75H.875v-2.188L5.533 6.28A3.938 3.938 0 019.188.875zm0-.875a4.812 4.812 0 00-4.654 6.042l-4.342 4.34a.656.656 0 00-.192.465v2.497c0 .362.294.656.656.656H3.72a.656.656 0 00.656-.656V12.25H5.47a.656.656 0 00.656-.656V10.5h.528a.66.66 0 00.464-.192l.842-.842A4.812 4.812 0 109.188 0zM10.5 2.953a.548.548 0 11-.001 1.095.548.548 0 01.001-1.095zm0-.765a1.312 1.312 0 100 2.624 1.313 1.313 0 000-2.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
});