define("ember-svg-jar/inlined/square-exclamation-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#E41E2A\" d=\"M48.737 0H6.31A5.786 5.786 0 00.523 5.786v42.428A5.786 5.786 0 006.309 54h42.428a5.786 5.786 0 005.786-5.786V5.786A5.786 5.786 0 0048.737 0zm1.929 48.214a1.93 1.93 0 01-1.929 1.929H6.31a1.93 1.93 0 01-1.929-1.929V5.786A1.93 1.93 0 016.31 3.857h42.428a1.93 1.93 0 011.929 1.929v42.428zM27.523 37.125a3.375 3.375 0 100 6.75 3.375 3.375 0 000-6.75zm-1.385-25.554h2.77c.822 0 1.48.685 1.445 1.507l-.844 20.25a1.446 1.446 0 01-1.445 1.386h-1.082a1.446 1.446 0 01-1.445-1.386l-.844-20.25a1.447 1.447 0 011.445-1.507zm1.385 25.554a3.375 3.375 0 100 6.75 3.375 3.375 0 000-6.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "55",
      "height": "54",
      "viewBox": "0 0 55 54"
    }
  };
});