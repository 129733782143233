define("ember-svg-jar/inlined/checklist-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M11.9 15.664H1.7V3.481h2.025l-.236.481c-.13.27-.117.59.038.847a.848.848 0 00.723.412h5.1a.848.848 0 00.723-.412.891.891 0 00.038-.847l-.236-.481H11.9v12.183zM6.475 1.74h.65l.85 1.74h-2.35l.85-1.74zm2.55 0L8.411.481A.85.85 0 007.65 0h-1.7a.85.85 0 00-.76.481l-.615 1.26H0v15.664h13.6V1.74H9.025zM3.4 12.183h1.7v-1.74H3.4v1.74zm3.4 0h3.4v-1.74H6.8v1.74zm-3.4-3.48h1.7V6.961H3.4v1.74zm3.4 0h3.4V6.961H6.8v1.74z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "18",
      "viewBox": "0 0 14 18"
    }
  };
});