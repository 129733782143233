define("ember-svg-jar/inlined/left-long-arrow-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M3.746 6.89l.191-.19a.26.26 0 00.11-.22.34.34 0 00-.11-.246l-2.296-2.27h10.28a.316.316 0 00.233-.095.316.316 0 00.096-.232v-.274a.316.316 0 00-.096-.232.316.316 0 00-.232-.096H1.64L3.936.765a.34.34 0 00.11-.245.26.26 0 00-.11-.22l-.19-.19A.327.327 0 003.513 0a.327.327 0 00-.233.11L.11 3.28A.26.26 0 000 3.5a.26.26 0 00.11.219L3.28 6.89c.073.073.15.109.233.109.082 0 .16-.036.232-.11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "7",
      "viewBox": "0 0 13 7"
    }
  };
});