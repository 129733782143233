define("ember-svg-jar/inlined/R-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.082 33V10.6h10.08c1.429 0 2.72.31 3.872.928 1.173.619 2.09 1.472 2.752 2.56.661 1.067.992 2.283.992 3.648 0 1.408-.331 2.677-.992 3.808a7.145 7.145 0 01-2.72 2.624c-1.152.64-2.454.96-3.904.96h-4.896V33H7.082zm12.416 0l-5.696-10.112 5.568-.8L25.706 33h-6.208zm-7.232-12.032h4.416c.554 0 1.034-.117 1.44-.352.426-.256.746-.608.96-1.056.234-.448.352-.96.352-1.536 0-.576-.128-1.077-.384-1.504a2.49 2.49 0 00-1.12-1.024c-.47-.235-1.046-.352-1.728-.352h-3.936v5.824z\" fill=\"#D16200\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});