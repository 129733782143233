define("ember-svg-jar/inlined/box-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M3.668 0L0 3.669V16h12.384l3.615-4.52V0H3.668zm.662 1.6h8.939l-1.6 1.6H2.73l1.6-1.6zm8.47 2.731l1.6-1.6v8.188l-1.6 2V4.331zM1.6 14.4h9.6V4.8H1.6v9.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});