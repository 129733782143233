define("ember-svg-jar/inlined/external-link-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.43 10.41a.333.333 0 010-.471L14.405.964l-.02-.02-2.498.01a.333.333 0 01-.333-.333V.343c0-.184.149-.333.333-.333L15.664 0c.184 0 .334.15.334.333l-.01 3.777c0 .185-.15.334-.333.334h-.278a.333.333 0 01-.333-.334l.01-2.498-.02-.02-8.975 8.975a.333.333 0 01-.472 0l-.157-.157zm6.444-4.322l-.223.222a.333.333 0 00-.097.236v6.34a.444.444 0 01-.445.445H1.333a.444.444 0 01-.444-.444V3.11c0-.246.199-.445.444-.445h9.442a.333.333 0 00.236-.097l.222-.223a.333.333 0 00-.235-.568H1.333A1.334 1.334 0 000 3.11v9.776c0 .736.597 1.333 1.333 1.333h9.776c.737 0 1.334-.597 1.334-1.333V6.323a.333.333 0 00-.57-.235z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "15",
      "viewBox": "0 0 16 15"
    }
  };
});