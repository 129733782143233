define("ember-svg-jar/inlined/info-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.518 15.481c-3.024-3.023-3.024-7.94 0-10.963 3.025-3.024 7.94-3.024 10.963 0 3.025 3.023 3.025 7.94 0 10.963-3.023 3.025-7.938 3.025-10.963 0m13.517-1.425c1.706-3.375 1.154-7.598-1.668-10.419a8.999 8.999 0 00-12.73 0 8.998 8.998 0 000 12.73 8.997 8.997 0 0010.42 1.666s2.825-1.454 3.978-3.977M9 9v6h2V9zm0-1h2V6H9z\"/>",
    "attrs": {
      "height": "18",
      "viewBox": "0 0 19 19",
      "width": "18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});