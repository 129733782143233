define("ember-svg-jar/inlined/report-success-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M10.399 14.4h-.026a4.004 4.004 0 01-3.977-4c0-2.205 1.794-4 4-4 2.205 0 4 1.795 4 4a4.004 4.004 0 01-3.997 4zm1.831-6.402l1.131 1.132-3.765 3.765-2.165-2.166 1.13-1.13 1.035 1.034 2.634-2.635zm-10.63 6.4V4.332L4.33 1.6h6.07v3.2h-.003a5.607 5.607 0 00-5.6 5.6c0 1.565.648 2.98 1.687 3.998H1.6zm10.399-9.36V0h-8.33L0 3.668v12.33h10.35l.046.002c3.087 0 5.6-2.512 5.6-5.6 0-2.53-1.689-4.67-3.997-5.362z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});