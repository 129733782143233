define("ember-svg-jar/inlined/system-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M0 0h11.21L14 2.87V16H0V0zm1.556 1.6v12.8h10.888V3.532L10.566 1.6h-9.01zm1.553 11.198v-4h1.555v4H3.11zm3.11 0v-8.8h1.556v8.8H6.22zm3.112 0v-6.4h1.556v6.4H9.33z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16"
    }
  };
});