define("ember-svg-jar/inlined/circle-chevron-right-regular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M464 256a208 208 0 11-416 0 208 208 0 11416 0zM0 256a256 256 0 10512 0 256 256 0 10-512 0zm241 121l104-104c9.4-9.4 9.4-24.6 0-33.9L241 135c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l87 87-87 87c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
});