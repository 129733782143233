define("ember-svg-jar/inlined/W-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.848 23L.128.6h5.536l3.36 11.776c.15.533.277 1.077.384 1.632.128.533.224 1.045.288 1.536.085.49.15.95.192 1.376.043.405.085.747.128 1.024h-.704c.128-.79.235-1.493.32-2.112.107-.619.224-1.195.352-1.728.128-.555.288-1.13.48-1.728l3.328-8.864h4.352l3.264 8.864c.256.704.47 1.365.64 1.984.17.619.299 1.216.384 1.792.107.555.192 1.11.256 1.664l-.64.096c.043-.384.075-.736.096-1.056.021-.341.043-.661.064-.96.043-.32.085-.65.128-.992.043-.341.107-.715.192-1.12.085-.427.203-.907.352-1.44L26.208.6h5.408L24.896 23h-4.16l-5.312-12.896.608.064L11.136 23H6.848z\" fill=\"#D16200\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});