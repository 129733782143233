define("ember-svg-jar/inlined/street-view-thin-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M10.211 4.383c.339-.457.54-1.022.54-1.634A2.753 2.753 0 008 0a2.753 2.753 0 00-2.75 2.75c0 .612.2 1.177.539 1.634a2.316 2.316 0 00-1.54 2.18v2.331A1.66 1.66 0 005.5 10.5v1.844a1.66 1.66 0 001.657 1.657h1.688c.913 0 1.656-.743 1.656-1.657V10.5a1.66 1.66 0 001.25-1.606V6.562c0-1.004-.643-1.86-1.539-2.179zM8 1a1.75 1.75 0 110 3.5A1.75 1.75 0 018 1zm2.75 7.895a.656.656 0 01-.656.657H9.5v2.793a.656.656 0 01-.656.657H7.156a.656.656 0 01-.656-.657V9.551h-.594a.656.656 0 01-.656-.657V6.562c0-.725.587-1.312 1.312-1.312h.293c.71.326 1.55.34 2.29 0h.293c.725 0 1.312.587 1.312 1.312v2.332zM16 13.001c0 2.41-5.582 3-8 3-2.414 0-8-.59-8-3 0-1.237 1.468-1.994 3.225-2.439.204.328.467.616.775.85v.014c-1.62.33-3 .852-3 1.575 0 1.447 5.239 2 7 2 2.197 0 7-.652 7-2 0-.75-1.402-1.259-3-1.576v-.014c.308-.233.57-.52.775-.848C14.532 11.007 16 11.764 16 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});