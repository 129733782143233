define("ember-svg-jar/inlined/note-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14 9.875c0 .417-.146.77-.438 1.063l-2.624 2.624A1.447 1.447 0 019.875 14H1.5c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 010 12.5v-11C0 1.083.146.73.438.437A1.447 1.447 0 011.5 0h11c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062v8.375zm-3.781 2.969l2.625-2.625A.834.834 0 0013 10h-3v2.969a.464.464 0 00.219-.125zM13 1.5a.487.487 0 00-.14-.36.487.487 0 00-.36-.14h-11a.487.487 0 00-.36.14.487.487 0 00-.14.36v11a.49.49 0 00.14.36.49.49 0 00.36.14H9V9.75c0-.208.073-.385.219-.531A.723.723 0 019.75 9H13V1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
});