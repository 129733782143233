define("ember-svg-jar/inlined/steering-wheel-regular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.--><path d=\"M232 369v93.6C133.7 451.3 56.2 371.4 48.6 272h107.8l74.3 95.5c.4.5.8 1 1.3 1.5zm48 0c.4-.5.9-1 1.3-1.5l74.3-95.5h107.8c-7.6 99.4-85 179.3-183.4 190.6V369zm72-145l-7.2-14.3c-5.4-10.8-16.5-17.7-28.6-17.7H195.8c-12.1 0-23.2 6.8-28.6 17.7L160 224H50.4C65.8 124.3 152 48 256 48s190.2 76.3 205.6 176H352zm-96 288a256 256 0 100-512 256 256 0 100 512z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 512 512"
    }
  };
});