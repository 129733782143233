define("ember-svg-jar/inlined/step-backward-solid-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><clipPath id=\"id-13b\"><use xlink:href=\"#id-12a\"/></clipPath><path transform=\"translate(-4 -2)\" id=\"id-12a\" d=\"M4.359 8.786l6 4.981c.643.538 1.64.088 1.64-.769V3.002c0-.857-.997-1.307-1.64-.77l-6 5.02a1 1 0 000 1.534z\"/></defs><use xlink:href=\"#id-12a\" stroke-width=\"2\" clip-path=\"url(#id-13b)\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "8",
      "height": "12",
      "viewBox": "0 0 8 12"
    }
  };
});