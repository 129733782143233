define("ember-svg-jar/inlined/magnifying-glass-thin-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M.088 14.121a.317.317 0 00-.088.234c0 .098.03.186.088.264l.293.264A.364.364 0 00.645 15c.097 0 .175-.04.234-.117l3.78-3.78a.278.278 0 00.116-.234v-.293a6.01 6.01 0 001.89 1.187c.713.283 1.46.425 2.241.425a5.918 5.918 0 003.062-.82 6.157 6.157 0 002.212-2.213c.547-.927.82-1.948.82-3.061a5.918 5.918 0 00-.82-3.062A6.157 6.157 0 0011.968.82 5.918 5.918 0 008.906 0a5.918 5.918 0 00-3.061.82 6.157 6.157 0 00-2.212 2.212 5.918 5.918 0 00-.82 3.062c0 .781.141 1.528.424 2.241a6.01 6.01 0 001.187 1.89H4.13a.364.364 0 00-.264.117l-3.78 3.78zm8.818-2.871c-.937 0-1.802-.23-2.593-.688a5.08 5.08 0 01-1.875-1.875 5.075 5.075 0 01-.688-2.593c0-.938.23-1.802.688-2.593a5.08 5.08 0 011.875-1.875A5.075 5.075 0 018.906.938c.938 0 1.802.229 2.593.688a5.08 5.08 0 011.875 1.875c.459.791.688 1.655.688 2.593 0 .937-.229 1.802-.688 2.593a5.08 5.08 0 01-1.875 1.875 5.075 5.075 0 01-2.593.688z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15"
    }
  };
});